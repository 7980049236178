import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import useCustomer from '@/views/customer/useCustomer'
import util from '@/global/util'
import CustomerModal from '@/modal/customerModal'
import constant from '@/global/const'
import http from '@/global/http'

export default function useCustomerOfficeCreate() {
  const {
    customerMenuType,
    renderCustomerOfficeTitle,
    renderCustomerOfficeContent,
    //
    checkCustomerUnique,
    createCustomer,
    fetchCustomer,
    updateCustomer,
  } = useCustomer()

  const duplicateError = ref(null)
  const formBaseStatus = ref([])
  const customer = CustomerModal()

  const fakeDataCustomerOffice = () => {
    console.log('fakeDataCustomerOffice')
    setTimeout(() => {
      customer.company_name = 'CTY CDT'
      customer.host_name = 'DEV TEST'
      customer.host_phone1 = '0384928392'
      customer.email = 'email@gmail.com'
      customer.area_description = 'area_description'
      customer.region_description = 'region_description'
      customer.budget_description = 'budget_description'
      customer.more_description = 'more_description'
      customer.vehicle_description = '3 xe oto 2 xe may'
      customer.move_in_at = '2022/12/22'
      customer.purpose_description = 'purpose_description'
      customer.viewed_office = 'viewed_office'
      customer.over_time_description = 'over_time_description'
      customer.contract_time = 3
      customer.current_office = 'current_office'
      customer.more_info = 'more_info'
      customer.price_from = 2
      customer.price_to = 20000
      customer.area_from = 10
      customer.area_to = 1000
      customer.reception_at = '2022/12/15'
    }, 1000)
  }

  const districts = computed(() => store.state.app.districts)
  const wardsByDistricts = computed(() => store.state.app.wardsByDistricts)
  const streetsByDistricts = computed(() => store.state.app.streetsByDistricts)

  watch(() => customer.formality, n => {
    if (!n) return
    customer.currency = customer.formality === 'thue' ? 'usd' : 'billion'
  })
  watch(() => customer.province_id, n => {
    if (!n) return
    store.dispatch('app/fetchDistricts', { province_id: n })
  })
  watch(() => customer.district_ids, n => {
    store.dispatch('app/fetchStreetsByDistricts', http.buildQuery({ district_ids: n }))
    store.dispatch('app/fetchWardsByDistricts', http.buildQuery({ district_ids: n }))
  })
  watch([() => customer.width, () => customer.length, () => customer.floor_number], n => {
    const w = parseFloat(n[0] || 0, 10)
    const l = parseFloat(n[1] || 0, 10)
    const f = parseFloat(n[2] || 0, 10)
    if (!w || !l) return
    customer.acreage = w * l * (f + 1)
  }, { immediate: true })
  watch([() => customer.acreage, () => customer.input_price, () => customer.currency, () => customer.type_of_payment], ([a, p, c, t]) => {
    const val = t === 'm2' ? a * p : p
    customer.price = val
    if (c.toLowerCase() === 'million') {
      customer.price = val * 1000000
    }
    if (c.toLowerCase() === 'billion') {
      customer.price = val * 1000000000
    }
    customer.priceDisplay = `${util.formatNumber(val || 0)} ${constant.CURRENCY.find(item => item.value === c)?.text?.toUpperCase()}`
  })
  watch(() => customer.transfer_price, n => {
    customer.transferPriceDisplay = `${util.formatNumber(n || 0)} ${constant.CURRENCY.find(item => item.value === (customer.transfer_price_currency || 'usd'))?.text?.toUpperCase()}`
  })

  return {
    duplicateError,
    formBaseStatus,
    districts,
    wardsByDistricts,
    streetsByDistricts,
    customer,
    customerMenuType,
    fakeDataCustomerOffice,
    renderCustomerOfficeTitle,
    renderCustomerOfficeContent,
    //
    checkCustomerUnique,
    fetchCustomer,
    createCustomer,
    updateCustomer,
  }
}
