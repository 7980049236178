<template>
  <b-card
    no-body
    class="custom-border-top-1px"
  >
    <b-card-header>
      <b-badge
        variant="light-primary"
        class="font-small-4 px-1 py-50"
      >
        TÀI LIỆU ĐÍNH KÈM
      </b-badge>
    </b-card-header>
    <b-card-body>
      <div
        class="files-wrapper"
        :class="{'files-inside': files && files.length}"
      >
        <div class="file-item">
          <uploads
            id="uploads"
            ref="uploads"
            class="hidden-all-item"
            :files="files"
            @file-uploaded="handleUploaded"
            @file-removed="handleRemoved"
            @all-done="handleAllDone"
          />
        </div>
        <div
          v-for="(file, idx) in files"
          :key="'item-image-' + idx"
          class="file-item"
          :class="{'featured-image': value.image === file.origin}"
        >
          <viewer :images="[file]">
            <img
              :src="getSrcByFile(file)"
              class="h-100 w-100"
            >
            <div class="file-name">
              {{ file.filename }}
            </div>
            <feather-icon
              icon="ExternalLinkIcon"
              size="24"
              class="viewer-item-icon open-new-tab"
              @click="openLinkInNewTab(getUrlFile(file.origin, getUrlWithHost ? today : value.updated_at))"
            />
            <feather-icon
              icon="DownloadIcon"
              size="24"
              class="viewer-item-icon download"
              @click="downloadImg(file.origin, file.origin)"
            />
            <feather-icon
              icon="XCircleIcon"
              size="32"
              class="text-danger viewer-item-icon remove"
              @click="removeImage(file)"
            />
            <b-badge
              v-if="file.origin !== value.image && isImage(file)"
              class="viewer-item-icon selected"
              variant="secondary"
              @click="selectedImage(file)"
            >
              Ảnh đại diện
            </b-badge>
          </viewer>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BBadge, BCard, BCardBody, BCardHeader, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Uploads from '@core/components/uploads/Uploads.vue'
import { ref, computed } from '@vue/composition-api'

const ICON_PDF = require('@/assets/images/icons/icon-pdf.png')

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    Uploads,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
      required: true,
    },
    isUploading: {
      type: Boolean,
      default: false,
    },
    getUrlWithHost: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    'value.document_files': function (n) {
      this.files = n
    },
  },
  methods: {
    getSrcByFile(file) {
      if (this.IMAGE_TYPE.split(' ').includes(file.origin.split('.').pop())) {
        return this.getUrlFile(file.origin, this.getUrlWithHost ? this.today : this.value.updated_at)
      }
      if (this.PDF_TYPE.split(' ').includes(file.origin.split('.').pop())) {
        return ICON_PDF
      }
      return '@/assets/images/icons/icon-pdf.png'
    },
    selectedImage(img) {
      this.value.image = img.origin
    },
    removeImage(img) {
      this.handleRemoved(Object.assign(img, { name: img.name || img.origin }))
    },
    showPreviewFile(file, type = 'pdf') {
      if (type === 'pdf') {
        this.currentFilePdfUrl = file.origin
        this.isShowPdfPreview = true
      }
    },
    handleUploaded(files = []) {
      this.files.push(...files)
      this.$emit('update-files', this.files)
    },
    handleRemoved(file) {
      const foundIdx = this.files.findIndex(f => f.name === file.name)
      if (foundIdx !== -1) {
        this.files.splice(foundIdx, 1)
      }
    },
    handleAllDone() {
      this.$emit('all-updated', this.files)
    },
  },
  setup() {
    const files = ref([])
    const IMAGE_TYPE = 'png gif jpg jpeg jfif pjpeg pjp svg webp apng'
    const PDF_TYPE = 'pdf'
    const today = new Date().toISOString()

    const imgFiles = computed(() => {
      const imgs = files.value.filter(f => IMAGE_TYPE.split(' ')
        .includes(f.origin.split('.').pop()))
      return imgs || []
    })
    const pdfFiles = computed(() => {
      const pdfs = files.value.filter(f => PDF_TYPE.split(' ')
        .includes(f.origin.split('.').pop()))
      return pdfs || []
    })
    const isImage = file => IMAGE_TYPE.split(' ').includes(file.origin.split('.').pop())

    return {
      IMAGE_TYPE,
      PDF_TYPE,
      files,
      today,
      imgFiles,
      pdfFiles,
      isImage,
    }
  },
}
</script>

<style lang="scss">
.files-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 200px));
  grid-template-rows: minmax(100px, 200px);
  padding: 1rem;
  border: 1px solid gainsboro;
  gap: 1rem;

  &:not(.files-inside) {
    grid-template-columns: 1fr;
  }

  .file-item {
    position: relative;
    border-radius: 1rem;
    border: 2px solid gainsboro;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 30px;
      background-color: rgba(255, 255, 255, 0.8);
    }

    &:hover {
      box-shadow: 0 0 10px 0 gray;
    }

    &.featured-image {
      border-color: #51bcda;
      box-shadow: 0 0 10px 0 #51bcda;
    }

    .file-name {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #ffffff80;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }

    .viewer-item-icon {
      position: absolute;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 10px 0 #51bcda;
      }

      &.open-new-tab {
        bottom: .25rem;
        left: .5rem;
      }

      &.download {
        bottom: .25rem;
        left: 40px;
      }

      &.remove {
        top: .5rem;
        right: .5rem;
        background: #ffffffd9;
        border-radius: 50%;
        box-shadow: 0 0 10px 0 grey;
      }

      &.selected {
        bottom: 0;
        right: .5rem;
        padding: .5rem 1rem;
      }
    }
  }
}
</style>
