<template>
  <div
    v-if="buttons.length"
    class="d-flex sticky-right justify-content-end"
    :class="customClass"
  >
    <b-button
      v-if="btnSuggest"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-tooltip.hover="btnSuggest.tooltip"
      :disabled="loading"
      class="suggest-action"
      :class="buttons.length > 1 ? ('text-' + btnSuggest.value) + ' border-' + btnSuggest.value : ('bg-' + btnSuggest.value)"
      @click.prevent="$emit('click', btnSuggest)"
    >
      <feather-icon
        v-if="loading"
        icon="SettingsIcon"
        class="cursor-pointer spinner"
      />
      <feather-icon
        v-else-if="btnSuggest.icon"
        :icon="btnSuggest.icon"
      />
      {{ btnSuggest.label }}
    </b-button>
    <b-dropdown
      v-if="buttons.length > 1"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      v-b-tooltip.hover.topleft="'Hiện tất cả thao tác'"
      variant="primary"
      no-caret
      class="custom-b-dropdown"
    >
      <template #button-content>
        <feather-icon
          icon="ChevronUpIcon"
          size="24"
        />
        <feather-icon
          icon="ChevronUpIcon"
          class="animate-scale"
          size="24"
        />
      </template>

      <b-dropdown-item
        v-for="(button, idx) in buttons.filter(b => b.value !== btnSuggest.value)"
        :key="idx"
        v-b-tooltip.hover.left="button.tooltip"
        :class="idx===0 ? '' : 'mt-50'"
        @click.prevent="$emit('click', button)"
      >
        <div
          class="font-weight-bolder"
          :class="'text-' + button.value"
        >
          <feather-icon
            v-if="button.icon"
            size="16"
            :icon="button.icon"
          />
          {{ button.label }}
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import {
  BButton, BDropdown, BDropdownItem, BOverlay, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BOverlay,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: {
    buttons: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    btnSuggest() {
      return this.buttons.find(b => b.suggest) || this.buttons[0]
    },
    customClass() {
      let cClass = ''
      cClass += (this.btnSuggest && (this.buttons.length > 1)) ? 'has-suggest ' : ''
      cClass += (this.buttons.length === 1) ? 'no-list ' : ''
      return cClass
    },
  },
}
</script>

<style lang="scss">
.custom-b-dropdown {
  > button {
    padding: .5rem;
  }
}

.animate-scale {
  position: absolute;
  left: 7px;
  top: 7px;
  animation: animate-up 1s infinite;
}

@keyframes animate-up {
  from {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  50% {
    opacity: .2;
  }
  90% {
    opacity: 0;
  }
  to {
    transform: translateY(-.5rem) scale(1.4);
    opacity: 0;
  }
}
</style>
