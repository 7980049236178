import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    checkCustomerUnique: (ctx, data) => axiosIns.post('/api.client/check', data),
    createCustomer: (ctx, data) => axiosIns.post('/api.client', data),
    fetchCustomer: (ctx, data) => axiosIns.get(`/api.client/${data.id}`),
    fetchCustomers: (ctx, q) => axiosIns.get(`/api.client/${q || ''}`),
    fetchUserCustomers: (ctx, q) => axiosIns.get(`/api.client/${q || ''}`),
    fetchCustomersReport: (ctx, q) => axiosIns.get(`/api.client/report${q || ''}`),
    updateCustomer: (ctx, data) => axiosIns.put(`/api.client/${data.id}`, data),
    closeDealCustomer: (ctx, data) => axiosIns.post(`/api.client/${data.id}/close-deal`, data),
    openDealCustomer: (ctx, id) => axiosIns.delete(`/api.client/${id}/close-deal`),
    deleteCustomer: (ctx, id) => axiosIns.delete(`/api.client/${id}`),
    quotation: (ctx, data) => axiosIns.post('/api.quotation', data),
    fetchSuggestedListProduct: (ctx, data) => axiosIns.get(`/api.product/${data.id}/fit${data.q || ''}`),
    exportExcel: (ctx, q) => axiosIns.post(`/api.client/export${q || ''}`),
    fetchHistoryUpdateCustomer: (ctx, { id }) => axiosIns.get(`/api.client/history/${id}`),
  },
}
